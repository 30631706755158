import React from 'react';

export default function SVGBack(): JSX.Element {
  return (
    <svg
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      className="text-textPrimary stroke-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0511 1.15039L2 9.17097L10.0511 17.1504" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
