import { Logger } from './logger';
import NullLogger from './NullLogger';

export const portalPrefix = '[CivicPassPortal]';
export default class Log {
  static currentLogger: Logger = new NullLogger();

  static setLogger(logger: Logger): void {
    this.currentLogger = logger;
  }

  static debug(message: string, ...optionalParams: unknown[]): void {
    this.currentLogger.debug(`${portalPrefix} ${message}`, optionalParams);
  }

  static error(message: string, error: Error | null = null, ...optionalParams: unknown[]): void {
    this.currentLogger.error(`${portalPrefix} ${message}`, error, optionalParams);
  }
}
