import React, { createContext, useState, useContext, ReactNode } from 'react';

interface PrincipalContextProps {
  principal: string | null;
  setPrincipal: (principal: string | null) => void;
}

interface PrincipalProviderProps {
  children: ReactNode;
}

const PrincipalContext = createContext<PrincipalContextProps | undefined>(undefined);

export const PrincipalProvider: React.FC<PrincipalProviderProps> = ({ children }) => {
  const [principal, setPrincipal] = useState<string | null>(null);

  return <PrincipalContext.Provider value={{ principal, setPrincipal }}>{children}</PrincipalContext.Provider>;
};

export const usePrincipal = (): PrincipalContextProps => {
  const context = useContext(PrincipalContext);
  if (!context) {
    throw new Error('usePrincipal must be used within a PrincipalProvider');
  }
  return context;
};
