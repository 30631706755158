import tw from 'twin.macro';

const baseButtonStyle =
  'relative cursor-default border-[2px] border-gray-400 border rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-secondary';
const baseOptionsStyle =
  'absolute mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 whitespace-normal';

const StyledListbox = tw.div`
  [.listbox-content]:(relative mt-1 z-[50])
  [.listbox-content-right]:(relative z-[50])
  [.listbox-button]:(${baseButtonStyle} w-full)
  [.listbox-button-fit-min]:(${baseButtonStyle} w-min)
  [.listbox-button-title]:(block truncate)
  [.listbox-button-icon-container]:(pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2)
  [.listbox-button-icon]:(text-primary h-5 w-5)
  [.listbox-options]:(${baseOptionsStyle} w-full )
  [.listbox-options-fit-min]:(${baseOptionsStyle} min-w-max wide:min-w-[280px] wide:max-w-[300px])
  [.listbox-option]:(relative cursor-default select-none py-2 pl-10 pr-4 font-semibold text-secondary hover:bg-primary hover:text-white text-primary)
  [.listbox-option-title]:(block truncate font-normal flex w-full)
  [.listbox-option-title-selected]:(block truncate font-extrabold flex w-full)
  [.listbox-option-title-type]:(ml-2 w-full pt-[2px] text-right text-xs font-normal text-primary)
  [.listbox-option-selected-icon-container]:(text-secondary absolute inset-y-0 left-0 flex items-center pl-3 hover:text-white)
  [.listbox-option-selected-icon]:(h-5 w-5 text-primary hover:bg-primary hover:text-white)
`;

export default StyledListbox;
