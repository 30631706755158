import React, { useContext, useMemo, useState } from 'react';
import { PassType, PortalConfig, defaultPassType, getPortalConfig } from '../common/config';

export type PassProps = {
  setPass: React.Dispatch<React.SetStateAction<PassType>>;
  pass: PassType;
  passConfig: PortalConfig;
};

const PassContext = React.createContext<PassProps>({
  setPass: () => {},
  pass: defaultPassType,
  passConfig: getPortalConfig(defaultPassType),
});

export const PassProvider: React.FC<{ children: unknown }> = ({ children = null }) => {
  const [pass, setPass] = useState<PassType>(defaultPassType);

  const config = useMemo(() => {
    return getPortalConfig(pass, { id: 'icp', name: 'ic' });
  }, [pass]);

  return (
    <PassContext.Provider
      value={{
        setPass,
        pass,
        passConfig: config,
      }}
    >
      <>{children}</>
    </PassContext.Provider>
  );
};

export const usePassProvider = (): PassProps => useContext(PassContext);
