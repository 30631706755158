// This utils module will likely export more functions later,
// to avoid having to rewrite import code, disable this rule.
// eslint-disable-next-line import/prefer-default-export
export const isProd = process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'prod-devnet';

export const isProdMainnet = process.env.REACT_APP_STAGE === 'prod';

export const isUniquenessPortal = (): boolean => process.env.REACT_APP_PASS_TYPE === 'uniqueness';

// Extracts the base domain from a URL
export function extractBaseDomain(redirectParam: string): string {
  const redirectUrl = new URL(redirectParam);
  return redirectUrl.hostname.split('.').slice(-2).join('.');
}

// Returns the redirect chain mapping for a given hostname if it exists
export function getRedirectChainMapping(
  redirectParam: string,
  redirectChainMappings: { [key: string]: { evm: boolean; solana: boolean } }
): { evm: boolean; solana: boolean } | null {
  const hostname = extractBaseDomain(redirectParam);
  const patterns = Object.keys(redirectChainMappings);
  const matchingPattern = patterns.find((pattern) => new RegExp(pattern).test(hostname));
  return matchingPattern ? redirectChainMappings[matchingPattern] : null;
}

export const getKeyByValue = (object: Record<string, string>, value: string): string => {
  return Object.keys(object).find((key) => object[key] === value) || '';
};
