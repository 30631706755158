import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GatewayStatus, useGateway } from '@civic/icp-gateway-react';
import StatusPanelContent from './StatusPanelContent';
import { useRedirect } from '../hooks/useRedirect';
import analytics from '../analytics';
import Log from '../logging';

export type TokenStatusPanelProps = {
  connected: boolean;
};
export default function TokenStatusPanel({ connected }: TokenStatusPanelProps): JSX.Element {
  const history = useHistory();
  const [status, setStatus] = useState<string>(GatewayStatus[GatewayStatus.CHECKING]);
  const googleAnalyticsEvent = `Token ${status} - Civic Pass`;
  const { gatewayStatus, requestGatewayToken } = useGateway();

  useRedirect({ condition: gatewayStatus === GatewayStatus.ACTIVE, code: GatewayStatus[gatewayStatus] });

  useEffect(() => {
    Log.debug('TokenStatusPanel useEffect', { gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus] });
    setStatus(GatewayStatus[gatewayStatus]);
  }, [gatewayStatus]);

  const handleButtonClick = () => {
    requestGatewayToken?.();
  };

  const handleLearnMore = () => {
    history.push(`/documentation${`?chain=${'icp'}`}`);
  };

  useEffect(() => {
    analytics.pageView(window.location.pathname);
  }, [googleAnalyticsEvent]);

  return (
    <>
      <div style={{ visibility: 'visible' }}>
        <StatusPanelContent
          connected={connected}
          status={status}
          handleButtonClick={handleButtonClick}
          handleLearnMore={handleLearnMore}
        />
      </div>
    </>
  );
}
