import { Logger } from './logger';

export default class LocalLogger implements Logger {
  private logConsole: Console = console;

  debug(message: string, ...optionalParams: unknown[]): void {
    this.logConsole.log(message, optionalParams);
  }

  error(message: string, error: Error | null, ...optionalParams: unknown[]): void {
    this.logConsole.error(`${message}: ${error}`, optionalParams);
  }
}
