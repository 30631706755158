import React from 'react';
import { Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { PrincipalProvider } from './common/Components/PrincipalContext';
import TokenStatusScreen from './screens/TokenStatusScreen';
import DocumentationScreen from './screens/DocumentationScreen';

export default function App(): JSX.Element {
  return (
    <>
      <PrincipalProvider>
        <Route path="/" exact component={TokenStatusScreen} />
      </PrincipalProvider>
      <Toaster position="bottom-left" reverseOrder={false} />
    </>
  );
}
