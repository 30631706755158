import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { isUniquenessPortal } from '../utils/util';
import PassSelector from '../Components/PassSelector';
import analytics from '../analytics';
import ICPConnectButton from '../Components/ICPConnectButton';
import { useQueryParams } from '../hooks/useQueryParams';

interface StatusPanelContentProps {
  status: string;
  handleButtonClick: () => void;
  handleLearnMore: () => void;
  connected: boolean;
}

const StyledPanel = tw.div`flex flex-col items-center full:flex-row full:-space-x-40 justify-center`;
const CardContainer = tw.div`block max-w-xl rounded-xl bg-white p-6 shadow-lg w-full border-[#f1f5f9] border`;
const CardContent = tw.div`flex flex-col justify-center items-center full:items-center`;
const PassSelectorContainer = tw.div`px-4 full:px-7 flex flex-col items-center mb-4`;
const LinkButton = tw.button`text-base leading-5 font-bold disabled:text-textInactive text-textTertiary hover:text-textBase`;
const StatusButton = tw.button`relative border border-white`;

interface ImageContainerProps {
  grayscale?: boolean;
}

const CardImage: React.FC<ImageContainerProps> = ({ grayscale = false }) => (
  <div className={`full:w-9/12 w-1/2 items-center -mb-7 ${grayscale ? 'grayscale' : ''}`}>
    <img src="card.png" alt="active" />
  </div>
);

export default function StatusPanelContent({
  status,
  handleButtonClick,
  handleLearnMore,
  connected,
}: StatusPanelContentProps): JSX.Element {
  const { t } = useTranslation();
  const { redirectParam } = useQueryParams();

  const handleLearnMoreClick = () => {
    analytics.event('button', 'click', 'learn more', t('screens.tokenStatus.supportLink', { context: status }));
    handleLearnMore();
  };

  const handleConnectClick = () => {
    analytics.event('button', 'click', 'connect', t('screens.tokenStatus.buttonLabel', { context: status }));
    handleButtonClick();
  };

  return (
    <StyledPanel>
      <CardContainer>
        <CardContent>
          <PassSelectorContainer>
            <PassSelector />
          </PassSelectorContainer>

          {!connected && (
            <div className="flex flex-col items-center">
              <CardImage />
              <div className={`mt-10 multi-connect-button ${connected ? 'icp' : ''}`} data-testid="ICPConnectButton">
                <ICPConnectButton />
              </div>
            </div>
          )}

          {connected && (
            <>
              {/* When refresh is needed, show additional copy */}
              {status === 'REFRESH_TOKEN_REQUIRED' && (
                <div className="flex flex-col items-center">
                  <CardImage grayscale />
                  <span className="mt-9 text-sm items-center text-center mb-3 text-black">Your verification is:</span>
                  <StatusButton className={`status-button ${status} mb-3 text-black`} onClick={handleConnectClick}>
                    <span className={`dot ${status} mr-2 text-black`} />
                    <span>{t('screens.tokenStatus.buttonLabel', { context: status })}</span>
                  </StatusButton>

                  <LinkButton disabled={false} onClick={handleLearnMoreClick}>
                    <span>{t('screens.tokenStatus.supportLink', { context: status })}</span>
                  </LinkButton>
                </div>
              )}

              {/* For any other status show the status button */}
              {status !== 'REFRESH_TOKEN_REQUIRED' && (
                <div className="flex flex-col items-center">
                  <CardImage />
                  {redirectParam && status === 'ACTIVE' && (
                    <span className="mt-9 text-sm items-center text-center mb-3 text-black">
                      {t('screens.tokenStatus.redirectMessage')}
                    </span>
                  )}
                  <StatusButton className={`status-button ${status} mt-9 mb-5`} onClick={handleConnectClick}>
                    <span className={`dot ${status} mr-2`} />
                    <span>{t('screens.tokenStatus.buttonLabel', { context: status })}</span>
                  </StatusButton>

                  {status !== 'ACTIVE' && (
                    <LinkButton disabled={false} onClick={handleLearnMoreClick}>
                      <span>{t('screens.tokenStatus.supportLink', { context: status })}</span>
                    </LinkButton>
                  )}
                </div>
              )}
            </>
          )}
        </CardContent>

        {/* <>{!R.isEmpty(description) && <p className="mb-5">{description}</p>}</> */}
        <p className="mt-4 mb-5">{isUniquenessPortal() ? t('screens.tokenStatus.uniquenessBlurb') : ''}</p>
      </CardContainer>
    </StyledPanel>
  );
}

CardImage.defaultProps = {
  grayscale: false,
};
