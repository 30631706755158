import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './common/i18n/i18n';
import './common/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './providers/theme/ThemeProvider';
import analytics from './common/analytics';
import initializeLogger from './common/logging/initializeLogger';

initializeLogger();
analytics.initialize();

const ConditionalStrictMode: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Disable strict mode if stage === e2e to prevent double - rendering of components
  const disableStrictMode =
    process.env.REACT_APP_DISABLE_STRICT_MODE ||
    ['e2e', 'preprod_e2e'].includes((process.env.REACT_APP_STAGE || 'unknown').toLowerCase());
  return disableStrictMode ? <>{children}</> : <React.StrictMode>{children}</React.StrictMode>;
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConditionalStrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* <div>test</div> */}
    </ThemeProvider>
  </ConditionalStrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
