/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';

const getParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const DEFAULT_DELAY = 0; // no delay before redirect

type RedirectProps = {
  condition: boolean;
  code?: string;
  error?: string;
  delay?: number;
  url?: string;
};

/**
 * Will redirect to the url in the redirectUrl query param if the condition is true
 * @param condition The condition to check before redirecting
 * @param delay The delay in milliseconds before redirecting (defaults to no delay)
 * @param url The url to redirect to (defaults to the redirectUrl query param)
 * @param code The oauth-style code to send to the redirect target (if any)
 * @param error The oauth-style error to send to the redirect target (if any)
 */
export const useRedirect = ({ condition, delay = DEFAULT_DELAY, url, code, error }: RedirectProps): void => {
  useEffect(() => {
    const redirectTo = url || getParam('redirect_uri');
    if (condition && redirectTo !== null) {
      const state = getParam('state');

      const fullUrl = new URL(redirectTo);
      if (code) fullUrl.searchParams.append('code', code);
      if (error) fullUrl.searchParams.append('error', error);
      if (state) fullUrl.searchParams.append('state', state);

      setTimeout(() => {
        window.location.replace(fullUrl.toString());
      }, delay);
    }
  }, [condition]);
};
