import React, { useState } from 'react';
import { AuthClient } from '@dfinity/auth-client';
import { usePrincipal } from './PrincipalContext';

const iiUrl = 'https://identity.ic0.app';
const derivationOrigin = 'https://73ncn-4qaaa-aaaag-alddq-cai.icp0.io';

const ICPConnectButton = (): JSX.Element | null => {
  const [connected, setConnected] = useState(false);
  const { setPrincipal } = usePrincipal();

  const connectToICPChain = async () => {
    console.log('Connecting to ICP chain...', derivationOrigin);
    const authClient = await AuthClient.create();
    await new Promise<void>((resolve, reject) => {
      authClient.login({
        identityProvider: iiUrl,
        derivationOrigin,
        onSuccess: resolve,
        onError: reject,
      });
    });
    return authClient;
  };

  return (
    <>
      <button
        type="button"
        onClick={async () => {
          const authClient = await connectToICPChain();
          const identity = authClient.getIdentity();
          console.log('Principal:', identity.getPrincipal().toText());
          setPrincipal(identity.getPrincipal().toText());
          setConnected(true);
        }}
        disabled={connected}
      >
        {connected ? 'Connected to ICP' : 'Connect to ICP'}
      </button>
      {connected}
    </>
  );
};

export default ICPConnectButton;
