import ReactGA from 'react-ga4';
import googleAnalyticsConfig from './googleAnalytics.config';
import { Analytics } from '../analytics';

export default class GoogleAnalytics implements Analytics {
  initialize(): void {
    const env = process.env.REACT_APP_STAGE;
    if (env && googleAnalyticsConfig[env]) {
      ReactGA.initialize(googleAnalyticsConfig[env], {
        gaOptions: { cookieFlags: 'samesite=none;secure' },
      });
    }
  }

  event(eventCategory: string, eventAction: string, eventLabel: string, eventValue?: string): void {
    if (ReactGA.isInitialized) {
      ReactGA.send({ hitType: 'event', eventCategory, eventAction, eventLabel, eventValue });
    }
  }

  pageView(title: string): void {
    if (ReactGA.isInitialized) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
    }
  }
}
