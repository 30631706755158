import React, { useEffect, useMemo } from 'react';
import { ICPGatewayProvider } from '@civic/icp-gateway-react/dist/esm/ICPGatewayProvider';
import TokenStatusPanel from './TokenStatusPanel';
import { usePrincipal } from '../Components/PrincipalContext';
import { usePassProvider } from '../../providers/PassProvider';
import { defaultPassType, getPortalConfig } from '../config';

export default function ICPTokenStatusPanel(): JSX.Element {
  const { principal } = usePrincipal();
  const connected = principal !== null;
  const icpWallet = useMemo(() => ({ principal: principal ?? undefined }), [principal]);
  const { pass } = usePassProvider();
  const config = getPortalConfig(defaultPassType);
  const gatekeeperNetwork = config.passConfigs[pass].gatekeeperNetworkPublicKey;

  return (
    <>
      <ICPGatewayProvider wallet={icpWallet} gatekeeperNetwork={gatekeeperNetwork}>
        <TokenStatusPanel connected={connected} />
      </ICPGatewayProvider>
    </>
  );
}

ICPTokenStatusPanel.defaultProps = {};
