import React, { useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { usePassProvider } from '../../providers/PassProvider';
import { PassType, defaultPassType, getPortalConfig, isDemoDomain } from '../config';
import StyledListbox from './StyledListbox';
import { useQueryParams } from '../hooks/useQueryParams';

const PASS_MAPPING = {
  captcha: 'ignite',
  liveness: 'vaal',
  identity: 'blueNile',
  uniqueness: 'uniqueness',
  ageCheck13: 'ageCheck13',
  videoSelfieAgeCheck13: 'faceAgeCheck13',
  civicDemoPass: 'civicDemoPass',
};

export default function SelectPassListBox(): React.ReactElement {
  const { setPass, pass } = usePassProvider();
  const { t } = useTranslation();
  const config = getPortalConfig(defaultPassType);
  const { passParam, scopeParam } = useQueryParams();
  const [passTypes, setPassTypes] = useState<string[]>(Object.keys(config.passConfigs));
  /**
   * If the pass type is set in the query param, set the pass type based on the query param
   * The scope parameter limits the options available, if it's set then limit the options to those set in scope
   */
  useEffect(() => {
    // on initial render, instantiate the pass provider based on query param if it exists
    const validPassTypes = Object.keys(config.passConfigs);
    const passName = PASS_MAPPING[passParam];

    if (passName && validPassTypes.includes(passName)) {
      setPass(passName);
    }
    if (scopeParam.length > 0) {
      const scopePassTypes = scopeParam
        .filter((scope) => Object.keys(PASS_MAPPING).includes(scope))
        .map((scope) => PASS_MAPPING[scope]);
      setPassTypes(scopePassTypes.length > 0 ? scopePassTypes : validPassTypes);
      const selectedPass = passName && scopePassTypes.includes(passName) ? passName : scopePassTypes[0];
      setPass(selectedPass);
    } else if (isDemoDomain()) {
      const demoPass = !passParam && passTypes.find((passType) => passType === 'civicDemoPass');
      if (demoPass) {
        setPass(demoPass as PassType);
      }
    }
  }, []);

  return passTypes.length === 1 ? (
    <h1 className="text-primary">{t(`passes.${passTypes[0]}`)}</h1>
  ) : (
    <StyledListbox className={passTypes.length === 1 ? 'hidden' : ''}>
      <Listbox
        value={pass}
        onChange={(passType) => {
          setPass(passType);
        }}
      >
        <div className="listbox-content">
          <Listbox.Button className="listbox-button-fit-min">
            <span className="listbox-button-title">{t(`passes.${pass}`)}</span>
            <span className="listbox-button-icon-container">
              <ChevronDownIcon className="listbox-button-icon" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="listbox-options-fit-min">
              {passTypes.map((passType) => (
                <Listbox.Option key={passType} className="listbox-option" value={passType}>
                  {({ selected }) => (
                    <span>
                      <span>{t(`passes.${passType}`)}</span>
                      {selected ? (
                        <span className="listbox-option-selected-icon-container">
                          <CheckIcon className="listbox-option-selected-icon" aria-hidden="true" />
                        </span>
                      ) : null}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </StyledListbox>
  );
}
