/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
// A custom hook that builds on useLocation to parse
// the query string for you.
// https://v5.reactrouter.com/web/example/query-parameters
import { useLocation, useHistory } from 'react-router-dom';

export const useQueryParams = (): {
  chainsParam: string[];
  passParam: string;
  updateChain: (string) => void;
  scopeParam: string[];
  redirectParam: string;
} => {
  const { search } = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(search);
  const chainsParam = params.get('chain')?.split(',') || [];
  const passParam = params.get('pass') || params.get('scope');
  const scopeParam = params.get('scope')?.split(',') || [];
  const redirectParam = params.get('redirect_uri') || '';

  useEffect(() => {
    const chain = chainsParam.length === 1 ? chainsParam[0] : '';
    if (!chain) return;
    const formattedChainName = chain.charAt(0).toUpperCase() + chain.slice(1);
    document.title = `Civic Pass Portal: ${formattedChainName}`;
  }, [chainsParam.length]);

  const updateChain = (chainName: string) => {
    const newParams = new URLSearchParams(params);
    if (chainName === '') {
      newParams.delete('chain');
    } else {
      newParams.set('chain', chainName);
    }

    history.push({ search: newParams.toString() });
  };

  return { chainsParam, passParam: passParam || '', updateChain, scopeParam, redirectParam };
};
