import React, { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import SVGBack from '../svg/SVGBack';
import { useQueryParams } from '../hooks/useQueryParams';
import ICPConnectButton from '../Components/ICPConnectButton';
import { usePrincipal } from '../Components/PrincipalContext';

interface ChildProps {
  children: ReactChild | ReactChild[];
  // eslint-disable-next-line react/require-default-props
  showBackButton?: boolean;
}

export default function MainContainer({ children, showBackButton }: ChildProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const description = t('screens.walletConnection.description');
  const { principal } = usePrincipal();
  const connected = principal !== undefined;
  const { chainsParam } = useQueryParams();

  const chain = chainsParam.length === 1 ? chainsParam[0] : '';
  const location = useLocation();

  const isOnStatusPage = location.pathname.includes('status');

  const { userAgent } = navigator;
  const isAndroid = /android/i.test(userAgent);
  const isiOS = /iphone|ipad|ipod/i.test(userAgent);
  const isDesktop = !isAndroid && !isiOS;

  const showConnectButton = (isAndroid && connected) || (isiOS && connected) || isDesktop;

  const backAction = () => {
    history.goBack();
  };

  const chainImage = () => {
    const defaultImg = 'ethereum.svg';

    if (!chain) {
      return <></>;
    }
    const chainWithoutSpaces = chain.replace(/\s/g, '');

    const url = chainWithoutSpaces ? `${chainWithoutSpaces}.svg` : defaultImg;
    // render chain image or default image on error
    return (
      <img
        src={url}
        alt="Chain logo"
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null; // prevents looping
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = defaultImg;
        }}
        className="ml-2"
        // TODO these should be relative and determined by the container size rather than fixed
        width={122}
        height={33}
      />
    );
  };

  return (
    <>
      <div
        className="main-container flex flex-col p-5 wide:p-0 wide:pt-5 z-1 relative bg-gray-50"
        data-testid="passPortalConnectScreen"
      >
        <div className="mb-5">
          <div>
            {showBackButton && (
              <>
                <div className="float-left ml-0.5 mr-1 mt-6">
                  <SVGBack />
                </div>
                <div className="float-left mt-[1.35rem]">
                  <button type="button" onClick={backAction}>
                    {t('back')}
                  </button>
                </div>
              </>
            )}
            <div
              className={`flex justify-end wider:justify-center space-x-1 items-end relative ${
                isOnStatusPage ? '' : 'wider:hidden'
              } multi-connect-button ${connected ? 'icp' : ''}`}
              data-testid="multiConnectButton"
            >
              {showConnectButton && <ICPConnectButton data-testid="multiConnectButton" />}
            </div>
          </div>
        </div>
        <div>
          <div className="px-4 full:px-7 mt-4 mb-4 flex flex-col items-center relative">
            <div className="items-center flex flex-row pb-2">
              {chain ? (
                <>
                  <img src="civic-pass-x.svg" alt="Civic Pass Logo with X" />
                  {chainImage()}
                </>
              ) : (
                <img src="civic-pass.svg" alt="Civic Pass Logo" data-testid="heroImage" />
              )}
            </div>
            <div className="max-w-lg full:px-7 text-center">
              {showBackButton && <p>{description}</p>}
              {chain && !showBackButton && <p>{description}</p>}
              {!showBackButton && !chain && <p className="mb-16">{description}</p>}
            </div>
          </div>
        </div>

        <div className="min-h-[380px] full:min-h-[480px] full:px-7 absolute-center main-panel mb-10 relative">
          {' '}
          {children}
          {connected} {/* Embedding the IdentityButton conditionally */}
        </div>
        <div className="textFooter text-center px-7 text-gray-500">
          {showBackButton ? <p className="full:mt-14 mt-6">{t('copyright')}</p> : <p>{t('copyright')}</p>}
        </div>
      </div>
    </>
  );
}
