/* eslint-disable react/react-in-jsx-scope */
import 'react-confirm-alert/src/react-confirm-alert.css';
import MainContainer from '../common/MainContainer/MainContainer';
import ICPTokenStatusPanel from '../common/TokenStatus/ICPTokenStatusPanel';
import { PassProvider } from '../providers/PassProvider';

export default function TokenStatusScreen(): JSX.Element {
  return (
    <MainContainer>
      <PassProvider>
        <div className="main-panel" data-testid="icpMainPanel">
          <ICPTokenStatusPanel />
        </div>
      </PassProvider>
    </MainContainer>
  );
}
